<template>
  <v-container fluid class="pt-0">
    <div v-if="id" class="text-h5">编辑图书</div>
    <div v-else class="text-h5">添加图书</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>
    <v-row>
      <v-col :cols="6">
        <h1 class="mb-3">立体图</h1>
        <div style="width: 240px;min-height:300px;border: 1px solid #ccc;position:relative;padding: 3px"
             @mouseenter="showIcon"
             @mouseleave="hideIcon">
          <v-img :aspect-ratio="1.5/2" :src="(item.dimensional ? item.dimensional : '')+randomPath" style="width: 100%;"
                 v-if="id||hasPost"></v-img>
          <v-icon
              v-if="id"
              class="white rounded-circle pa-1"
              color="blue"
              :style="isActive ? centerShow : centerHide"
              @click="postImg"
          >mdi-pencil
          </v-icon>
          <v-icon
              v-else
              class="white rounded-circle pa-1"
              color="blue"
              :style="isActive ? centerShow : centerHide"
              @click="postImg"
          >mdi-plus-thick
          </v-icon>
        </div>
      </v-col>
      <v-col :cols="6">
        <h1 class="mb-3">平面图</h1>
        <div style="width: 240px;min-height:300px;border: 1px solid #ccc;position:relative;padding: 3px"
             @mouseenter="showCoverIcon"
             @mouseleave="hideCoverIcon">
          <v-img :aspect-ratio="1.5/2" :src="(coverItem.cover ? coverItem.cover : '')+coverRandomPath "
                 style="width: 100%;" v-if="id||coverHasPost"></v-img>
          <v-icon
              v-if="id"
              class="white rounded-circle pa-1"
              color="blue"
              :style="coverIsActive ? centerShow : centerHide"
              @click="postCoverImg"
          >mdi-pencil
          </v-icon>
          <v-icon
              v-else
              class="white rounded-circle pa-1"
              color="blue"
              :style="coverIsActive ? centerShow : centerHide"
              @click="postCoverImg"
          >mdi-plus-thick
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="书名" v-model="item.title" :rules="[v => !!v  || '书名不能为空']"
                      class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-select outlined item-text="name" item-value="id" :items="categories" label="图书类型" v-model="item.categories"
                  class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="编号" v-model="item.book_number" class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="作者" v-model="item.author" class="text-body-2"/>
      </v-col>
      <!--      <v-col cols="12" sm="6" class="pr-5">-->
      <!--        <v-select-->
      <!--            outlined-->
      <!--            item-text="value"-->
      <!--            item-value="id"-->
      <!--            :items="bindingList"-->
      <!--            label="装帧类型"-->
      <!--            v-model="item.binding"-->
      <!--            class="text-body-2"-->
      <!--        />-->
      <!--      </v-col>-->
      <!--      <v-col cols="12" sm="6" class="pr-5">-->
      <!--        <v-select-->
      <!--            outlined-->
      <!--            item-text="value"-->
      <!--            item-value="id"-->
      <!--            :items="paperList"-->
      <!--            label="纸张类型"-->
      <!--            v-model="item.paper"-->
      <!--            class="text-body-2"-->
      <!--        />-->
      <!--      </v-col>-->
      <!--      <v-col cols="12" sm="6" class="pr-5">-->
      <!--        <v-select-->
      <!--            outlined-->
      <!--            item-text="value"-->
      <!--            item-value="id"-->
      <!--            :items="formatList"-->
      <!--            label="开本类型"-->
      <!--            v-model="item.format"-->
      <!--            class="text-body-2"-->
      <!--        />-->
      <!--      </v-col>-->
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="原价" type="number" v-model="item.original_price" :rules="[v => !!v  || '价格不能为空']"
                      class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-text-field outlined label="折扣价" type="number" v-model="item.discount_price" :rules="[v => !!v  || '价格不能为空']"
                      class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-dialog
            ref="dialog"
            v-model="timeDialog"
            :return-value.sync="item.datetime"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="item.datetime"
                label="出版日期"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="item.datetime"
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="timeDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(item.datetime)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!--      <v-col cols="12" sm="6" class="pr-5">-->
      <!--        <v-text-field outlined label="库存" v-model="item.stocks" :rules="[v => !!v  || '库存不能为空']"-->
      <!--                      class="text-body-2"/>-->
      <!--      </v-col>-->
      <v-col cols="12" sm="12" class="pr-5">
        <v-textarea outlined label="简介" v-model="item.synopsis"
                    class="text-body-2"/>
      </v-col>
      <v-col cols="12" sm="6" class="pr-5">
        <v-btn color="primary" @click="update">提交</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>选择图片</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div style="width: 240px;">
            <v-img :aspect-ratio="1.5/2" :src="(item.dimensional ? item.dimensional : '')+randomPath"
                   style="width: 100%" v-if="id||hasPost"></v-img>
          </div>
          <!--          <input-->
          <!--              type="file"-->
          <!--              @change="filePreview"-->
          <!--          >-->
          <v-file-input
              @change="filePreview"
              prepend-icon="mdi-file-image"
              show-size
              label="Image"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              small
              @click="closeDialog"
          >取消
          </v-btn
          >
          <v-btn color="primary" text small @click="dialog=false"
          >确认
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="coverDialog" max-width="600px">
      <v-card>
        <v-card-title>选择图片</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div style="width: 240px;">
            <v-img :aspect-ratio="1.5/2" :src="(coverItem.cover ? coverItem.cover : ' ')+coverRandomPath "
                   style="width: 100%" v-if="id||coverHasPost"></v-img>
          </div>
          <!--          <input-->
          <!--              type="file"-->
          <!--              @change="filePreview"-->
          <!--          >-->
          <v-file-input
              @change="coverFilePreview"
              prepend-icon="mdi-file-image"
              show-size
              label="Image"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              small
              @click="closeCoverDialog"
          >取消
          </v-btn
          >
          <v-btn color="primary" text small @click="coverDialog=false"
          >确认
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {getBookDetail, updateBookInfo, getBookCategories, postBook} from "@/api/books";
import {mapState} from "vuex";
import COS from "cos-js-sdk-v5";
import {listBindingCategory, listFormatCategory, listPaperCategory} from "@/api/choose";

export default {
  name: "userHome",
  props: {
    id: [String, Number],
  },
  data: () => ({
    item: {},
    coverItem: {},
    categories: [],
    centerShow: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      display: 'block'
    },
    centerHide: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      display: 'none'
    },

    bindingList: [],
    paperList: [],
    formatList: [],

    isActive: false,
    coverIsActive: false,

    hasPost: false,
    coverHasPost: false,
    dialog: false,
    coverDialog: false,
    bookImg: null,
    coverBookImg: null,
    bookPath: '',
    bookId: '',

    timeDialog: false,

    randomPath: '?t=' + Math.random(),
    coverRandomPath: '?t=' + Math.random()
  }),
  created() {
    // 装帧类型
    listBindingCategory().then((res) => {
      res['results'].forEach((item) => {
        this.bindingList.push(item)
      })
    }).catch(() => {
    })
    // 纸张类型
    listPaperCategory().then((res) => {
      res['results'].forEach((item) => {
        this.paperList.push(item)
      })
    }).catch(() => {
    })
    // 开本类型
    listFormatCategory().then((res) => {
      res['results'].forEach((item) => {
        this.formatList.push(item)
      })
    }).catch(() => {
    })
  },

  computed: {
    ...mapState(['cos']),
  },
  watch: {
    'bookImg': function (newVal, oldVal) {
      this.randomPath = ''
    },
    'coverBookImg': function (newVal, oldVal) {
      this.coverRandomPath = ''
    }
  },

  methods: {
    async update() {
      let bookData = {...this.item};
      // bookData.dimensional=path

      const val = bookData.categories
      bookData.categories = [val]

      this.bookImg && (bookData.dimensional = '')
      this.coverBookImg && (bookData.cover = '')

      bookData.original_price=Number(bookData.original_price)
      bookData.discount_price=Number(bookData.discount_price)
      if (this.id) {
        const {id} = await updateBookInfo(bookData);
        this.bookId = id
      } else {
        const {id} = await postBook(bookData)
        this.bookId = id
      }
      console.log('立体图',this.bookImg,'平面图',this.coverBookImg)
      this.bookImg && this.uploadImg(this.bookImg)
      this.coverBookImg && this.uploadCoverImg(this.coverBookImg)
      this.bookId && this.$snackbar({
        content: this.id ? '修改成功' : '添加成功',
        color: 'success',
        showClose: true,
      })
    },
    showIcon() {
      this.isActive = true
    },
    hideIcon() {
      this.isActive = false
    },

    showCoverIcon() {
      this.coverIsActive = true
    },
    hideCoverIcon() {
      this.coverIsActive = false
    },


    closeDialog() {
      this.dialog = false
      this.hasPost = false
      this.id || (this.item.dimensional = null)
    },
    closeCoverDialog() {
      this.coverDialog = false
      this.coverHasPost = false
      this.id || (this.coverItem.cover = null)
    },


    postImg() {
      if (this.id) {
        this.dialog = true
        console.log('修改图片')
      } else {
        this.dialog = true
        console.log('上传图片')
      }
    },

    postCoverImg() {
      if (this.id) {
        this.coverDialog = true
        console.log('修改图片')
      } else {
        this.coverDialog = true
        console.log('上传图片')
      }
    },

    filePreview(e) {
      let _this = this
      let files = e
      this.bookImg = e
      if (!e || !window.FileReader) return  // 判断是否支持FileReader
      let reader = new FileReader()
      reader.readAsDataURL(files) // 文件转换
      reader.onloadend = function () {
        _this.item.dimensional = this.result
        _this.hasPost = true
      }
    },
    coverFilePreview(e) {
      let _this = this
      let files = e
      this.coverBookImg = e
      if (!e || !window.FileReader) return  // 判断是否支持FileReader
      let reader = new FileReader()
      reader.readAsDataURL(files) // 文件转换
      reader.onloadend = function () {
        _this.coverItem.cover = this.result
        _this.coverHasPost = true
      }
    },
    uploadImg(e) {
      const COS = require('cos-js-sdk-v5')

      const cos = new COS({
        SecretId: this.cos.tmpSecretId, // 身份识别ID
        SecretKey: this.cos.tmpSecretKey,// 身份秘钥
        XCosSecurityToken: this.cos.sessionToken

        // SecretId: 'AKIDznKbbKlxsVr3ycy3sQhgPr1uXswjFUR7', // 身份识别ID
        // SecretKey: 'QZR2NSGn20kDPK3ZomUaQzHI1TeZCYdS' // 身份秘钥
      })

      cos.putObject({
        Bucket: 'jkexpress-1255710621', /* 存储桶 */
        Region: 'ap-beijing', /* 存储桶所在地域，必须字段 */
        Key: `/dimensional/${this.item.book_number}${this.bookId.toString().padStart(8, '0')}.png`, /* 文件名 */
        StorageClass: 'STANDARD', // 上传模式, 标准模式
        Body: e, // 上传文件对象
        onProgress: (progressData) => { // 上传进度
          console.log(JSON.stringify(progressData))
        }
      }, (err, data) => {
        console.log(err || data)
        // 上传成功之后
        if (data.statusCode === 200) {
          // let path=`https://${data.Location}`
        }
      })
    },
    uploadCoverImg(e) {
      const COS = require('cos-js-sdk-v5')

      const cos = new COS({
        SecretId: this.cos.tmpSecretId, // 身份识别ID
        SecretKey: this.cos.tmpSecretKey,// 身份秘钥
        XCosSecurityToken: this.cos.sessionToken
      })
      console.log('coverID', this.coverItem)
      cos.putObject({
        Bucket: 'jkexpress-1255710621', /* 存储桶 */
        Region: 'ap-beijing', /* 存储桶所在地域，必须字段 */
        Key: `/cover/${this.item.book_number}${this.bookId.toString().padStart(8, '0')}.png`, /* 文件名 */
        StorageClass: 'STANDARD', // 上传模式, 标准模式
        Body: e, // 上传文件对象
        onProgress: (progressData) => { // 上传进度
          console.log(JSON.stringify(progressData))
        }
      }, (err, data) => {
        console.log(err || data)
        // 上传成功之后
        if (data.statusCode === 200) {
          // let path=`https://${data.Location}`
        }
      })
    },
  },
  mounted() {
    if (this.id) {
      getBookDetail(this.id)
          .then(result => {
            console.log(' item = ', result)
            this.item = JSON.parse(JSON.stringify(result));
            this.coverItem = JSON.parse(JSON.stringify(result));
            console.log('item:', this.item, 'coverItem:', this.coverItem)
            this.item.categories = this.item.categories[0]
          })
          .catch(error => {
          })
    }
    getBookCategories({type: 1})
        .then(result => {
          console.log(' categories = ', result.results)
          this.categories = result.results
        })
        .catch(error => {
        })
  },
};
</script>
<style scoped>

</style>

